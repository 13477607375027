import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBanner } from '../interfaces/IBanner';

@Injectable()
export class BannerState {
  private banner$ = new BehaviorSubject<IBanner | null>(null);

  private loading$ = new BehaviorSubject<boolean>(false);

  setLoading(value: boolean): void {
    this.loading$.next(value);
  }

  setBanner(banner?: IBanner) {
    this.banner$.next(banner ?? null);
  }

  getLoading$(): Observable<boolean> {
    return this.loading$.asObservable();
  }
}
