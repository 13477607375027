import { Injectable } from '@angular/core';
import { BaseApi } from '../../../core/base/BaseApi';
import { IApi } from '../../../core/interrfaces/IApi';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBanner } from '../interfaces/IBanner';
import { AppConfiguration } from '../../../core/services/configuration/app.configuration';

@Injectable()
export class BannerApi extends BaseApi implements IApi {
  constructor(
    private http: HttpClient,
    private appConfiguration: AppConfiguration
  ) {
    super();
  }

  buildBaseUrl(input: string): string {
    return this.appConfiguration.apiNewBaseUrl + '/' + input;
  }

  get(): Observable<IBanner | undefined> {
    return this.http
      .get<IBanner[]>(this.buildBaseUrl(`api/v2/user/banners`))
      .pipe(map(banners => banners?.[0]));
  }
}
