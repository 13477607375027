import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBanner } from './interfaces/IBanner';
import { BannerApi } from './api/banner.api';
import { BannerState } from './state/banner.state';
import { tap } from 'rxjs/operators';

@Injectable()
export class BannerFacade {
  constructor(
    private bannerApi: BannerApi,
    private bannerState: BannerState
  ) {}

  loadBanner(): Observable<IBanner | undefined> {
    return this.bannerApi.get().pipe(tap(banner => this.bannerState.setBanner(banner)));
  }

  get loading$(): Observable<boolean> {
    return this.bannerState.getLoading$();
  }

  startLoading(): void {
    return this.bannerState.setLoading(true);
  }

  stopLoading(): void {
    return this.bannerState.setLoading(false);
  }
}
